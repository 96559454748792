



































































































import { FormValidations } from "@/mixins/form-validations";
import { Notification } from "@/models/notification.interface";
import { Detail } from "@/models/detail.interface";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class DetailForm extends mixins(FormValidations, StyleCheck) {
  @Prop() dialog = false;
  @Prop() editDetail!: Detail;

  defaultDetail: Detail = {
    id: undefined,
    name: "",
    type: "",
    status: {
      name: "",
    },
  };
  detail: Detail = { ...this.defaultDetail };

  $refs!: {
    detailForm: HTMLFormElement;
  };
  loading = false;
  status = [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.INACTIVE];

  @Watch("editDetail")
  private loadDetail() {
    this.detail = this.editDetail;
  }

  private get formTitle() {
    return !this.detail.id
      ? this.$tc("Details.createTitle")
      : this.$tc("Details.editTitle");
  }

  private get detail_types(): { key: string; value: string }[] {
    const types: { key: string; value: string }[] = [];
    let keys = Object.keys(this.$constants.DETAIL_TYPES);
    for (const key of keys) {
      types.push({ key: key, value: this.$constants.DETAIL_TYPES[key] });
    }
    return types;
  }

  private close(newDetail?: Detail) {
    this.resetFormValidations([this.$refs.detailForm]);
    this.detail = Object.assign(this.detail, this.defaultDetail);
    this.$emit("closeDialog", newDetail);
  }

  private async createDetail() {
    this.loading = true;
    if (this.$refs.detailForm.validate()) {
      await this.$store
        .dispatch("details/createDetail", {
          detail: this.detail,
        })
        .then((newDetail) => {
          const Success: Notification = {
            message: this.$tc("Details.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          this.loading = false;
          this.close(newDetail);
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Details.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);

          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private async updateDetail() {
    this.loading = true;
    if (this.$refs.detailForm.validate()) {
      await this.$store
        .dispatch("details/updateDetail", {
          detail: this.detail,
        })
        .then(async () => {
          await this.$store.dispatch("details/updateDetailStatus", {
            id: this.detail.id as number,
            status: this.detail.status?.name,
          });
          const Success: Notification = {
            message: this.$tc("Details.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          this.loading = false;
          this.close();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Details.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);

          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }
}
