
























































































































import { FormValidations } from "@/mixins/form-validations";
import { Notification } from "@/models/notification.interface";
import { Term } from "@/models/term.interface";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class TermForm extends mixins(FormValidations, StyleCheck) {
  @Prop() dialog = false;
  @Prop() editTerm!: Term;

  defaultTerm: Term = {
    id: undefined,
    num: undefined,
    max_num: undefined,
    type: "",
    status: {
      name: "",
    },
  };
  term: Term = { ...this.defaultTerm };

  $refs!: {
    termForm: HTMLFormElement;
  };
  rangeSwitch = true;
  loading = false;
  status = [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.INACTIVE];

  @Watch("editTerm")
  private loadTerm() {
    this.term = this.editTerm;
  }

  private get formTitle() {
    return !this.term.id
      ? this.$tc("Terms.createTitle")
      : this.$tc("Terms.editTitle");
  }

  @Watch("dialog")
  private openDialog() {
    this.rangeSwitch = this.term.max_num ? true : false;
  }

  @Watch("rangeSwitch")
  private handleSwitch(value) {
    if (!value) {
      this.term.max_num = undefined;
    }
  }

  private get term_types(): { key: string; value: string }[] {
    const types: { key: string; value: string }[] = [];
    let keys = Object.keys(this.$constants.TERM_TYPES);
    for (const key of keys) {
      types.push({ key: key, value: this.$constants.TERM_TYPES[key] });
    }
    return types;
  }

  private close(newTerm?: Term) {
    this.resetFormValidations([this.$refs.termForm]);
    this.term = Object.assign(this.term, this.defaultTerm);
    this.$emit("closeDialog", newTerm);
  }

  private async createTerm() {
    this.loading = true;

    if (this.$refs.termForm.validate()) {
      if (
        this.term.num !== undefined &&
        this.term.max_num !== undefined &&
        parseInt(this.term.num as string) >
          parseInt(this.term.max_num as string)
      ) {
        const Error: Notification = {
          message: this.$tc("Views.tc-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);

        this.loading = false;
      } else {
        await this.$store
          .dispatch("terms/createTerm", {
            term: this.term,
          })
          .then(async (newTerm: Term) => {
            const Success: Notification = {
              message: this.$tc("Terms.success"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: false,
            };

            this.$store.dispatch("notifications/showNotification", Success);
            this.loading = false;
            this.close(newTerm);
          })
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("Terms.error"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);

            this.loading = false;
          });
      }
    } else {
      this.loading = false;
    }
  }

  private async updateTerm() {
    this.loading = true;
    if (this.$refs.termForm.validate()) {
      await this.$store
        .dispatch("terms/updateTerm", {
          term: this.term,
        })
        .then(async () => {
          await this.$store.dispatch("terms/updateTermStatus", {
            id: this.term.id as number,
            status: this.term.status?.name,
          });
          const Success: Notification = {
            message: this.$tc("Views.tf-1"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          this.loading = false;
          this.close();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Terms.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);

          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }
}
