




































import { Detail } from "@/models/detail.interface";
import { File as FileInterface } from "@/models/file.interface";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Carousel, Slide } from "vue-carousel";

@Component({
  components: {
    Carousel,
    Slide,
  },
})
export default class ProductDetailFile extends Vue {
  @Prop({ default: { files: [] } }) detail!: Detail;

  private isImage(file: File | FileInterface): boolean {
    if (file.type.match(/(jpg|jpeg|png|gif|image)$/)) {
      return true;
    } else {
      return false;
    }
  }

  private imagePreview(file: File | FileInterface): string {
    if ((file as FileInterface).id) {
      return (file as FileInterface).url;
    } else {
      return URL.createObjectURL(file);
    }
  }
}
