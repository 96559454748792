



























import { Category } from "@/models/category.interface";
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Category as CategoryInterface } from "@/models/category.interface";

@Component({})
export default class CategoryBreadcrumbs extends mixins(Navigation) {
  @Prop({ default: () => ({ id: 0, name: "", icon: "" }) }) category!: Category;
  @Prop({
    default: {
      all: [],
      tree: [],
    },
  })
  categories!: {
    all: Category[];
    tree: Category[];
  };
  @Prop({ default: false }) full!: boolean;
  @Prop({ default: false }) clickable!: boolean;
  @Prop({ default: "", required: false }) productName!: string;

  private goToCategory(category: CategoryInterface) {
    this.navigate("/catalogue/category/" + category.id + "/" + 1);
  }

  private findParent(
    categories: Category[],
    category: Category
  ): Category | undefined {
    if (!category.category || !(category.category as Category).id) {
      return undefined;
    } else {
      return categories.find((value) => {
        return value.id == (category.category as Category).id;
      });
    }
  }

  private getRoute(): Category[] {
    let route: Category[] = [];
    if (this.full) {
      route.push(this.category);
    }
    let parent = this.findParent(this.categories.all, this.category);
    while (parent) {
      route.push(parent);
      parent = this.findParent(this.categories.all, parent);
    }
    return route.reverse();
  }

  private get route(): Category[] {
    let route: Category[] = [];
    if (this.clickable) {
      route.push({ id: -1, name: "Categorías", icon: "" });
      this.getRoute().forEach((item) => {
        route.push(item);
      });
    } else {
      route = this.getRoute();
    }

    if (this.productName !== "") {
      route.push({ id: -2, name: this.productName, icon: "" });
    }

    return route;
  }
}
